import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button';
import { FormField } from '@/components/FormField';
import { PatientFormSchema } from '@/modules/PatientInformationForm/schema';
import { CustomerData } from '@/types/customer.types';

import styles from './PatientInformationForm.module.css';

type PatientInformationFormProps = {
  goBack: () => void;
  callback: (customer: CustomerData) => void;
};

export const PatientInformationForm = ({ goBack, callback }: PatientInformationFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerData>({
    resolver: zodResolver(PatientFormSchema),
  });

  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: 'Patient Information Form',
      });
    }
  }, []);

  const onSubmit: SubmitHandler<CustomerData> = (data) => {
    console.log('Submitting customer data:', data);
    callback(data);
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)} noValidate>
      <span className={styles.formTitle}>One last step, your patient information</span>
      <div className={styles.formContent}>
        <FormField
          label="Full Name"
          type="text"
          placeholder="Enter your full name"
          name="name"
          register={register}
          error={errors.name}
        />
        <FormField
          label="Phone"
          type="tel"
          placeholder="Enter your phone number"
          name="phone"
          register={register}
          error={errors.phone}
        />
        <FormField
          label="Email"
          type="email"
          placeholder="Enter your email"
          name="email"
          register={register}
          error={errors.email}
        />
      </div>
      <div className={styles.formBtnContainer}>
        <BackButton onClick={goBack} />
        <Button className={styles.formSubmitBtn} type="submit">
          Create Appointment
        </Button>
      </div>
    </form>
  );
};
