import 'react-day-picker/dist/style.css';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isSameDay, max, min } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import ReactGA from 'react-ga4';
import toast from 'react-hot-toast';

import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { SelectorContainer } from '@/components/SelectorContainer';
import { TIME_SLOT_NO_LONGER_AVAILABLE_MESSAGE } from '@/lib/errors';
import { getTimeSlotWithoutTimezone } from '@/lib/helpers/slots';
import { getPatientInfoFromQueryParams } from '@/lib/helpers/urlParams';
import { TimeSlotSelector } from '@/modules/TimeSlotSelector';
import { createAppointment } from '@/services/createAppointment';
import { useBookingStore } from '@/store/useBookingStore';
import { usePracticeStore } from '@/store/usePracticeStore';
import { MeetingDay } from '@/types/meetings.types';

import { ConfirmationScreen } from '../ConfirmationScreen';
import styles from './DoctorFreeSlots.module.css';

type DoctorFreeSlotsProps = {
  goBack: () => void;
  callback?: () => void;
};

export const DoctorFreeSlots = ({ goBack, callback }: DoctorFreeSlotsProps) => {
  const queryClient = useQueryClient();
  const { provider, appointmentType, slot, setSlot, onAppointmentCreated } = useBookingStore();
  const { freeSlots, setFreeSlots } = usePracticeStore();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [meetingDay, setMeetingDay] = useState<MeetingDay | null>(null);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    isPending,
    error,
    data: newAppointment,
    mutate,
  } = useMutation({
    mutationFn: () => {
      const patientInfo = getPatientInfoFromQueryParams();
      return createAppointment(provider, appointmentType, slot, freeSlots, patientInfo);
    },
  });

  const dateSlots = useMemo(
    () => freeSlots?.slots?.map((slot) => getTimeSlotWithoutTimezone(slot.time)) ?? [],
    [freeSlots?.slots],
  );
  const availableDates = useMemo(
    () =>
      dateSlots
        .filter((date, i, self) => self.findIndex((d) => isSameDay(d, date)) === i) // get unique dates
        .filter((day) => !isSameDay(new Date(), day)), // remove today's slots
    [dateSlots],
  );

  useEffect(() => {
    if (selectedDate) {
      const slotDay = availableDates
        .map((day) => ({
          date: day,
          slots: dateSlots.filter((slot) => isSameDay(slot, day)).map((slot) => ({ date: slot })),
        }))
        .find((slot) => isSameDay(slot.date, selectedDate));

      setMeetingDay(slotDay ?? null);
      setSlot(null);
      setShowCalendar(false);
    }
  }, [selectedDate, dateSlots, availableDates, setSlot]);

  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: 'Date Selector',
      });
    }
  }, []);

  const closeFoldOut = () => {
    setTimeout(() => {
      window.parent.postMessage(
        JSON.stringify({ action: 'foldOutClose', data: { type: 'iframe' } }),
        '*',
      );
    }, 7500);
  };

  useEffect(() => {
    if (newAppointment?.confirmed) {
      setShowConfirmation(true);
      onAppointmentCreated(newAppointment);
      closeFoldOut();
    }
  }, [newAppointment, onAppointmentCreated]);

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (!newAppointment?.error) {
      return;
    }
    const errMessage = newAppointment?.error?.toString();
    if (errMessage === TIME_SLOT_NO_LONGER_AVAILABLE_MESSAGE) {
      queryClient.invalidateQueries({ queryKey: ['freeSlots'] });
    }
    toast.error(errMessage);
  }, [newAppointment?.error, queryClient]);

  const onSelect = (day: Date | undefined) => {
    setSelectedDate(day);
  };

  const handleBack = () => {
    if (showCalendar) {
      setFreeSlots(null);
      goBack();
      return;
    }

    if (selectedDate && !showCalendar) {
      setShowCalendar(true);
      return;
    }
  };

  const handleConfirmation = () => {
    if (callback) {
      callback();
      return;
    }
    mutate();
  };

  if (!freeSlots) {
    return;
  }

  if (showConfirmation) {
    return <ConfirmationScreen />;
  }

  if (isPending) {
    return (
      <div className={styles.loader}>
        {' '}
        <Loader />
      </div>
    );
  }

  return (
    <SelectorContainer
      text={
        selectedDate && !showCalendar
          ? 'Choose from the available slots for'
          : 'Choose a date that best suits you'
      }
    >
      {showCalendar && (
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={onSelect}
          fromDate={min(availableDates)}
          toDate={max(availableDates)}
          disabled={(day) => !availableDates.some((d) => isSameDay(d, day))}
        />
      )}

      {selectedDate && !showCalendar && (
        <div className={styles.timeSlotContainer}>
          <TimeSlotSelector selectedDate={selectedDate} meetingDay={meetingDay} />
        </div>
      )}

      <div className={styles.timeSlotBtnContainer}>
        <BackButton onClick={handleBack} />

        <Button
          className={styles.timeSlotConfirmation}
          onClick={handleConfirmation}
          isActive={!!slot}
          disabled={!slot}
        >
          {callback ? 'Confirm Date and Time' : 'Create appointment'}
        </Button>
      </div>
    </SelectorContainer>
  );
};
