import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { Button } from '@/components/Button';
import { SelectorContainer } from '@/components/SelectorContainer';
import { getImageUrlFromPath } from '@/lib/helpers/images';
import { useBookingStore } from '@/store/useBookingStore';

import styles from './AppointmentTypeSelector.module.css';

const DEFAULT_APPOINTMENT_TYPE = 'appt-type-default-image.png';

export const AppointmentTypeSelector = () => {
  const { provider, appointmentType, setAppointmentType } = useBookingStore();

  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID && !appointmentType) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: 'Appointment Type Selector',
      });
    }
  }, [appointmentType]);

  const appointmentTypes = provider?.appointment_types ?? [];
  const hasAppointments = appointmentTypes.length > 0;

  const handleClick = (type: string) => {
    const newType = appointmentType === type ? null : type;
    setAppointmentType(newType, provider?.operatories ?? []);
  };

  if (!provider || !hasAppointments) {
    return null;
  }

  return (
    <SelectorContainer text="Select the desired appointment type:">
      {appointmentTypes.map(({ id, name }) => (
        <Button
          key={`appointmentType-${id}`}
          isActive={id === appointmentType}
          onClick={() => handleClick(id)}
          data-testid="appointment-slot"
          className={styles.btn}
        >
          <img
            className={styles.typeImg}
            src={getImageUrlFromPath(DEFAULT_APPOINTMENT_TYPE)}
            alt={name}
          />
          <span className={styles.typeName}>{name}</span>
        </Button>
      ))}
    </SelectorContainer>
  );
};
