import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { Button } from '@/components/Button';
import { formatTime, slotsAreEqual } from '@/lib/helpers/slots';
import { useBookingStore } from '@/store/useBookingStore';
import { MeetingDay, MeetingSlot } from '@/types/meetings.types';

import styles from './TimeSlotSelector.module.css';

interface TimeSlotSelectorProps {
  selectedDate: Date;
  meetingDay: MeetingDay | null;
}

export const TimeSlotSelector = ({ selectedDate, meetingDay }: TimeSlotSelectorProps) => {
  const { setSlot } = useBookingStore();
  const [selectedSlot, setSelectedSlot] = useState<MeetingSlot | null>(null);

  const handleClick = (slot: MeetingSlot) => {
    const newSlot = selectedSlot?.date && slotsAreEqual(slot.date, selectedSlot.date) ? null : slot;
    setSelectedSlot(newSlot);
    setSlot(newSlot);
  };

  useEffect(() => {
    if (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: 'Time Slot Selector',
      });
    }
  }, []);

  const formattedDate = format(selectedDate, 'PP');

  return (
    <>
      <span className={styles.date}>{formattedDate}</span>

      <div className={styles.slotsGrid}>
        {meetingDay?.slots.map((slot) => {
          const formattedTime = formatTime(slot.date);

          return (
            <Button
              key={`${formattedDate} - ${formattedTime}`}
              className={styles.slot}
              isActive={Boolean(selectedSlot?.date && slotsAreEqual(slot.date, selectedSlot.date))}
              onClick={() => handleClick(slot)}
              data-testid="meeting-slot"
            >
              {formattedTime}
            </Button>
          );
        })}
      </div>
    </>
  );
};
