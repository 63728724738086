import axios from 'axios';

import type {
  DearDocAppointment,
  DearDocAppointmentTypes,
  DearDocConnectionResponse,
  DearDocFreeSlots,
  DearDocProvider,
} from '@/types/api.types';

const BASE_API_URL = import.meta.env.VITE_API_URL || '';

export const getDearDocProviders = async (locationId: string): Promise<DearDocProvider[]> => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/locations/${locationId}/providers`,
  };
  try {
    const response = await axios.request(options);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getDearDocConnection = async (
  locationId: string,
): Promise<DearDocConnectionResponse> => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/locations/${locationId}/connection`,
  };
  try {
    const response = await axios.request(options);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getDearDocFreeSlots = async (
  locationId: string,
  providerId: string,
  appointmentType?: string | null,
): Promise<DearDocFreeSlots | undefined> => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/locations/${locationId}/providers/${providerId}?appointment_type=${
      appointmentType || ''
    }`,
  };
  try {
    const response = await axios.request(options);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getDearDocAppointmentTypes = async (
  locationId: string,
): Promise<DearDocAppointmentTypes | undefined> => {
  const options = {
    method: 'GET',
    url: `${BASE_API_URL}/locations/${locationId}/appointment_types`,
  };
  try {
    const response = await axios.request(options);
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const createDearDocAppointment = async (
  locationId: string,
  providerId: string,
  appointment: DearDocAppointment,
) => {
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/locations/${locationId}/providers/${providerId}`,
    data: appointment,
  };
  try {
    const response = await axios.request(options);
    return response.data;
  } catch (e) {
    if (!axios.isAxiosError(e)) {
      console.error(e);
      throw e;
    }
    const err = e.response?.data;
    return {
      error: err?.message || 'Sorry, something went wrong',
    };
  }
};
